import { useEffect, useState } from "react"
import Loader from "react-loaders"
import AnimatedLetters from "../AnimatedLetters"
import Logo from "../Home/Logo"
import './index.scss'

const Projects = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const [display, setDisplay] = useState('jungle')

    useEffect(() => {
        setTimeout(() => {
            return setLetterClass('text-animate-hover')
        }, 3000)
    })

    function handleRender(){
        if (display === "jungle") {
            return (
                <div>
                    <div className="iframe-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/b8dF0amqo1E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <p>{yourJungleDesc}</p>
                    <p>{yourJungleTech}</p>
                </div>
            )
        } else if (display === "cuisine") {
            return (
                <div>
                    <div className="iframe-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/z9VjowK8qEM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <p>{cuisineDesc}</p>
                    <p>{cuisineTech}</p>
                </div>
            )
        } else if (display === "hallow") {
            return (
                <div>
                    <div className="iframe-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/lkL6UGZP0V8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <p>{hallowDesc}</p>
                    <p>{hallowTech}</p>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="iframe-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/IoVdBv4esm4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <p>{yjbJobsDesc}</p>
                    <p>{yjbJobsTech}</p>
                </div>
            )
        }
    }

    const handleJungle = () => setDisplay("jungle")
    const handleCuisine = () => setDisplay("cuisine")
    const handleHallow = () => setDisplay("hallow")
    const handleYJB = () => setDisplay("yjb")


    const yourJungleDesc = "Bring the jungle to your fingertips with Your Jungle Book, a vibrant social platform dedicated to all things green. Here, you can showcase your personal collection of plants with photos, stay updated on the growth and well-being of others' plants, track watering status, and stay connected with fellow plant enthusiasts."
    const yourJungleTech = "Ruby on Rails as an API with ReactJS front-end. Utilizes PostgreSQL databases and is deployed on Render for both front and back-end. Implements Cloud Storage for users and their plant pictures."

    const cuisineDesc = "Unleash your culinary creativity with Cuisine Creations, a comprehensive recipe creation app that enables you to effortlessly create, rate, and store your favorite recipes in one place with your personal account."
    const cuisineTech = "Built using Ruby on Rails as a back-end API and a ReactJS front-end. Deployed through Render using a PostgreSQL database. Utilizes the Styled Components framework."

    const hallowDesc = "Experience a thrilling Halloween adventure with this game as you move around with your selected avatar, visit homes, knock on doors and collect candy. Keep your health bar up by consuming the candy, and move to the edge to discover more homes and gather even more sweets!"
    const hallowTech = "Built off React using basic database properties to implement new avatars for users. Interactive game with replayability, sounds and music, interactions, and randomness programmed in."

    const yjbJobsDesc = "Career Website with multiple routes and uses cloud databases to store applications and job opportunities."
    const yjbJobsTech = "Built using Python and Flask directing routes to a PlanetScale MySQL server and uses HTML, CSS, and Boostrap for the frontend"

    // const identityDesc = "An innovative app for creating alternate identities featuring storing identities, customizable information, all which helps keep your info off the net."
    // const identityTech = "Built on vanilla JavaScript. Utilizes a 3rd party API to generate Random User Information so you can keep your information private. Implements a key system to remember previously generated user information."

    return (
        <>
            <div className="container projects-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters 
                            letterClass={letterClass}
                            strArray={"Technical Projects".split('')}
                            idx={15}
                        />
                    </h1>
                    <div className="project-container">
                        <div className="project-titles">
                            <h2 onClick={handleJungle}>Your Jungle Book</h2>
                            <h2 onClick={handleCuisine}>Cuisine Creations</h2>
                            <h2 onClick={handleHallow}>Hallow House</h2>
                            <h2 onClick={handleYJB}>YJB Career Site</h2>
                        </div>
                        <div className="display-project">
                            {handleRender(display)}
                        </div>
                    </div>
                </div>
                {window.innerWidth > 768? <Logo /> : null}
            </div>
            <Loader type="pacman" />
        </>
    )
}

export default Projects