import './index.scss'
import LogoS from '../../../assets/images/RyLogoCrop.png'
import { useRef } from 'react'
import { useEffect } from 'react'
import gsap from 'gsap-trial'
import { motion } from "framer-motion";

const Logo = () => {

    const bgRef = useRef()
    const outlineLogoRef = useRef()
    const solidLogoRef = useRef()

    useEffect(() => {
        gsap
          .timeline()
          .to(bgRef.current, {
            duration: 1,
            opacity: 1,
          })
    
        gsap.fromTo(
          solidLogoRef.current,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            delay: 4,
            duration: 4,
          }
        )
    }, [])
      
    const pathVariants = {
        hidden: 
        {
            pathLength: 0
        },
        visible: 
        {
            pathLength: 1,
            transition: 
            {
                duration: 10,
            }
        }
    }
    
    return (
        <div className='logo-container' ref={bgRef} >
            <img ref={solidLogoRef} className='solid-logo' src={LogoS} alt="S" />
            <svg
                width="400pt"
                height="609pt"
                version="1.0"
                viewBox="203 0 602 1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    className="svg-container"
                    transform="translate(0 457) scale(.1 -.1)"
                    fill="none"
                >
                <motion.path
                    ref={outlineLogoRef}
                    d="M2798 9061 l-487 -6 -23 -23 -23 -23 -3 -4072 c-2 -3053 1 -4079 9
                    -4100 23 -55 38 -57 394 -57 344 0 370 3 391 47 5 10 11 500 14 1088 l5 1070
                    53 3 52 3 0 -1071 0 -1072 34 -34 34 -34 331 0 c234 0 338 3 356 12 57 26 55
                    -25 55 1128 l0 1061 136 -3 137 -3 703 -1084 c410 -633 712 -1090 726 -1097
                    18 -10 109 -13 391 -14 404 0 411 1 442 60 20 39 19 79 -4 115 -10 17 -309
                    480 -665 1030 l-647 1000 148 7 c534 23 989 198 1409 539 219 178 454 492 571
                    762 72 167 119 332 150 523 16 99 18 206 18 1219 0 1185 1 1171 -51 1390 -81
                    350 -248 652 -509 924 -393 408 -871 641 -1440 700 -119 12 -1993 21 -2707 12z
                    m397 -51 c-13 -24 -15 -84 -15 -370 l0 -340 -65 0 -65 0 0 -2260 0 -2260 65 0
                    65 0 0 -385 0 -385 -65 0 -65 0 0 -1076 0 -1076 -29 -29 -29 -29 -317 0 c-344
                    0 -365 3 -385 56 -6 16 -10 1521 -10 4075 l0 4051 29 29 29 29 436 0 437 0
                    -16 -30z m1395 15 c752 -80 1361 -471 1718 -1102 l40 -71 -95 79 c-124 104
                    -186 145 -308 206 -166 84 -315 128 -515 152 -54 7 -466 11 -1157 11 l-1073 0
                    0 341 0 341 29 29 29 29 599 0 c452 0 631 -4 733 -15z m920 0 c613 -65 1120
                    -332 1513 -795 246 -290 388 -613 449 -1015 19 -129 19 -2217 0 -2350 -61
                    -417 -210 -754 -475 -1072 -304 -364 -797 -649 -1277 -738 -128 -24 -334 -45
                    -437 -45 l-92 0 -36 61 c-21 33 -33 64 -29 68 5 5 48 22 96 39 158 57 339 158
                    547 306 149 106 184 137 297 270 122 141 238 314 319 475 35 70 70 133 79 140
                    26 22 107 160 148 252 55 124 87 261 99 416 6 81 9 506 6 1105 -4 1048 -2
                    1005 -58 1189 -41 136 -119 283 -209 394 -17 22 -46 67 -62 100 -132 261 -350
                    540 -557 714 -299 250 -700 430 -1101 494 -14 2 126 5 310 6 231 0 377 -4 470
                    -14z m-2330 -2986 l0 -2240 -52 3 -53 3 -3 2238 -2 2237 55 0 55 0 0 -2241z
                    m790 1 l0 -2240 -385 0 -385 0 0 2240 0 2240 385 0 385 0 0 -2240z m620 2216
                    c371 -58 704 -256 926 -551 118 -157 175 -271 221 -447 l27 -103 4 -1079 c3
                    -1175 4 -1159 -54 -1331 -92 -275 -297 -537 -549 -700 -157 -103 -281 -155
                    -480 -201 -114 -26 -136 -28 -407 -32 l-288 -4 0 2238 0 2237 253 -6 c141 -3
                    294 -13 347 -21z m920 1 c164 -31 322 -82 442 -143 163 -82 418 -301 459 -393
                    64 -146 116 -338 141 -526 19 -147 19 -2200 0 -2330 -28 -183 -93 -428 -139
                    -519 -21 -41 -86 -109 -179 -187 -176 -147 -365 -245 -578 -300 -173 -45 -229
                    -50 -636 -56 -217 -3 -393 -3 -390 0 3 3 61 20 130 37 525 136 929 567 1022
                    1090 10 59 13 296 13 1115 0 1152 3 1100 -66 1314 -36 112 -64 168 -134 276
                    -203 308 -498 517 -870 616 l-110 29 410 -4 c294 -4 431 -9 485 -19z m1012
                    -672 c72 -120 114 -232 148 -394 19 -89 20 -137 20 -1150 0 -1144 1 -1111 -55
                    -1298 -14 -45 -38 -108 -53 -140 -32 -67 -103 -186 -108 -181 -2 2 8 40 22 83
                    38 118 71 294 84 440 8 91 10 449 7 1180 -4 1005 -5 1055 -24 1160 -29 152
                    -57 255 -109 390 -11 30 25 -18 68 -90z m-198 -3422 c-123 -229 -328 -477
                    -506 -614 -199 -153 -436 -290 -613 -354 -54 -19 -104 -40 -111 -46 -11 -9 -5
                    -23 26 -69 22 -33 40 -61 40 -64 0 -4 -441 -6 -980 -6 l-980 0 0 384 0 385
                    1113 4 c1176 5 1161 4 1347 51 226 58 460 188 645 360 33 30 61 55 63 56 2 0
                    -18 -39 -44 -87z m-2354 -2239 l0 -1066 -29 -29 -29 -29 -317 0 c-344 0 -365
                    3 -385 56 -6 16 -10 433 -10 1080 l0 1054 385 0 385 0 0 -1066z m1881 27 c407
                    -628 669 -1043 669 -1057 0 -13 -13 -38 -29 -56 l-29 -33 -371 -3 -370 -3 -30
                    28 c-25 23 -1384 2113 -1398 2151 -4 10 85 12 442 10 l447 -3 669 -1034z"
                    variants={pathVariants}
                    initial="hidden"
                    animate="visible"
                />
                </g>
      </svg>

        </div>
    )
}

export default Logo