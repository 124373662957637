import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import { faCss3, faGitAlt, faHtml5, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRss } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loaders'

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    
    useEffect(() => {
        setTimeout(() => {
            return setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters 
                        letterClass={letterClass}
                        strArray={"About me".split('')}
                        idx={15}
                    />
                </h1>
                <p>I am a recent coding bootcamp graduate with a passion for full-stack development, particularly front-end and back-end technologies such as JavaScript, React, Ruby, Rails, HTML, and CSS. I have hands-on experience in creating web applications with CRUD functionality and RESTful routing conventions, using test-driven development and continuous integration and deployment.</p>
                <p>My interest in coding was piqued after completing my undergraduate degree in biology. I found myself drawn to the creative aspects of front-end development and the problem-solving challenges of back-end development. I enjoy tackling complex problems that require multidisciplinary perspectives, and finding innovative solutions to difficult challenges.</p>
                <p>Outside of coding, I enjoy various activities like snowboarding, wakeboarding, golf, exploring new coding languages, and most importantly, spending time with my amazing wife. I've always been a competitive person so you can get me on board with any type of game!</p>
                <p>I have always had a love for technology, and once I discovered my passion for coding, I have not looked back. Now, fully immersed in the world of Software Development, I find it fun and exciting. It gives me a sense of purpose and drive, and I can't wait to see where this journey takes me.</p>
            </div>

            <div className='stage-cube-cont'>
                <div className='cubespinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                    </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                    </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
                    </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon={faRss} color="#DD0031" />
                    </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
                    </div>
                    <div className='face6'>
                        <FontAwesomeIcon icon={faGitAlt} color="EC4D28" />
                    </div>
                </div>
            </div>
        </div>
        <Loader type="pacman" />
        </>
    )
}

export default About